import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { jwtDecode } from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private tokenKey = 'authToken';
  private loggedIn = new BehaviorSubject<boolean>(false); // Gerencia o estado do login

  isLoggedIn$ = this.loggedIn.asObservable(); // Observável para outras partes da aplicação

  constructor(private router: Router) {
    this.isLoggedIn(); // Verifica o login ao inicializar
  }

  // Método para verificar login status (sincroniza com o Guard)
  isLoggedIn(): boolean {
    return this.hasToken();
  }

  // Salva o token no localStorage
  setToken(token: string): void {
    localStorage.setItem(this.tokenKey, token);
    this.loggedIn.next(true);
  }

  // Novo método para obter o uuid do usuário a partir do token JWT
  getUserIdFromToken(): string | null {
    const token = localStorage.getItem(this.tokenKey);
    if (!token) return null;

    try {
      const decodedToken: any = jwtDecode(token); // Usa jwtDecode diretamente
      console.log(decodedToken)
      return decodedToken?.jti ?? null;
    } catch (error) {
      console.error('Erro ao decodificar o token', error);
      return null;
    }
  }

  // Remove o token e atualiza o estado de login
  logout(): void {
    localStorage.removeItem(this.tokenKey);
    this.loggedIn.next(false);
    this.router.navigate(['/']);
  }

  // Retorna um true or false para o token
  private hasToken(): boolean {
    return !!localStorage.getItem(this.tokenKey);
  }
}
